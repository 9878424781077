import{useState,useEffect,useRef}from "react";import{useNavigate}from "react-router-dom";import{auth,database,firebaseConfig}from "../../config/firebase";import Modal from "react-modal";import{getDatabase,ref,push,onValue,get,child,set,query,orderByChild,equalTo,}from "firebase/database";import{useAuth}from "../../contexts/AuthContext";function classNames(...classes){return classes.filter(Boolean).join(" ")}
export default function Profile(){localStorage.removeItem("refreshed");const navigate=useNavigate();const expayAPIUrl=firebaseConfig.expayApiUrl;const refreshedRef=useRef(!1);const[showConfirmationModal,setShowConfirmationModal]=useState(!1);const[showConfirmationModalForPin,setShowConfirmationModalForPIN]=useState(!1);const[username,setUsername]=useState("");const[apiResponse,setApiResponse]=useState(null);const[selectedButton,setSelectedButton]=useState(null);const[expirationTime,setExpirationTime]=useState(null);const[loading,setLoading]=useState(!1);const[customerNumber,setCustomerNumber]=useState("");const[cardNumber,setCardNumber]=useState("");const[rsaKeyIdData,setrsaKeyIdData]=useState("");const[rsaPEMData,setrsaPEMData]=useState("");const[tcnData,setTcnData]=useState("");const[yearOfBirth,setYearOfBirth]=useState("");const[otp,setOtp]=useState("");const[newPIN,setnewPIN]=useState("");const[pinChangeSuccess,setPinChangeSuccess]=useState(!1);const[lastFourDigits,setLastFourDigits]=useState("");const{currentUser,updateUserProfile,setError}=useAuth();console.log("currentuserfefwe",currentUser)
const updatePIN=async(e)=>{e.preventDefault();setShowConfirmationModalForPIN(!0)};const handleConfirmationForPin=async()=>{try{setPinChangeSuccess("");setOtp("");setYearOfBirth("");setnewPIN("");const usersRef=ref(database,`${firebaseConfig.dataPath}${currentUser.uid}`);const uid=currentUser.uid;console.log(currentUser)
const snapshot=await get(usersRef);if(snapshot.exists()){const userData=snapshot.val();const customerNum=userData.customerNum;const cardNum=userData.cardNum;const today=new Date();const todayFormatted=formatDate(today);const lastMonth=new Date();lastMonth.setMonth(lastMonth.getMonth()-1);const lastMonthFormatted=formatDate(lastMonth);function formatDate(date){const year=date.getFullYear();const month=String(date.getMonth()+1).padStart(2,"0");const day=String(date.getDate()).padStart(2,"0");return `${year}-${month}-${day}`}
const beginDate=lastMonthFormatted;const endDate=todayFormatted;console.log("Customer Number:",customerNum);console.log("Card Number:",cardNum);setCustomerNumber(customerNum);setCardNumber(cardNum);setError("");setLoading(!0);const user=currentUser;console.log("user info");console.log(user.uid);console.log(user);const token=user&&(await user.getIdToken());const payloadHeader={method:"POST",headers:{"Content-Type":"application/json",Authorization:`Bearer ${token}`,},body:JSON.stringify({apiKey:"YOUR_API_KEY",payload:{CustomerNumber:customerNum,WithSMS:!1,WithEmail:!1,},}),};const res=await fetch(expayAPIUrl+"/getTransactionPIN",payloadHeader);console.log(res);const apiResponse=await res.json();setApiResponse(apiResponse);console.log("RSA",rsaKeyIdData);setSelectedButton("pinchange");setrsaKeyIdData(apiResponse.rsaKey.Item.RsaKeyId);setTcnData(apiResponse.tcnNum.Item.TCN);setrsaPEMData(apiResponse.rsaKey.Item.PublicKeyInPemFormat);console.log("RSA",rsaPEMData)}}catch(e){console.log(e);setError("Failed to update profile")}
setShowConfirmationModalForPIN(!1);setLoading(!1)};const submitPINForm=async(e)=>{e.preventDefault();try{const usersRef=ref(database,`${firebaseConfig.dataPath}${currentUser.uid}`);const uid=currentUser.uid;console.log(currentUser)
const snapshot=await get(usersRef);if(snapshot.exists()){const userData=snapshot.val();const customerNum=userData.customerNum;const cardNum=userData.cardNum;setCustomerNumber(customerNum);setCardNumber(cardNum);setError("");setLoading(!0);const user=currentUser;console.log("user info");console.log(user.uid);console.log(user);const token=user&&(await user.getIdToken());const payloadHeader={method:"POST",headers:{"Content-Type":"application/json",Authorization:`Bearer ${token}`,},body:JSON.stringify({apiKey:"YOUR_API_KEY",payload:{CustomerNumber:customerNum,YOB:yearOfBirth,TCN:tcnData,LastFourDigits:lastFourDigits,EncryptedNewFourDigitPIN:newPIN,},}),};const res=await fetch(expayAPIUrl+"/changePIN",payloadHeader);console.log(res);console.log("success");const apiResponse=await res.json();setApiResponse(apiResponse);if(apiResponse.ErrorDescription==="Successfull"){setPinChangeSuccess(!0)}else{console.log("failed");setPinChangeSuccess(!1)}}}catch(e){console.log(e);setError("Failed to update profile")}
setLoading(!1)};const handleCancelConfirmationForPin=()=>{setShowConfirmationModalForPIN(!1)};const transactionHistory=async(e)=>{e.preventDefault();try{const usersRef=ref(database,`${firebaseConfig.dataPath}${currentUser.uid}`);const uid=currentUser.uid;console.log(currentUser)
const snapshot=await get(usersRef);if(snapshot.exists()){const userData=snapshot.val();const customerNum=userData.customerNum;const cardNum=userData.cardNum;const today=new Date();const todayFormatted=formatDate(today);const lastMonth=new Date();lastMonth.setMonth(lastMonth.getMonth()-1);const lastMonthFormatted=formatDate(lastMonth);const secondLastMonth=new Date();secondLastMonth.setMonth(secondLastMonth.getMonth()-2);const secondLastMonthFormatted=formatDate(secondLastMonth);const thirdLastMonth=new Date();thirdLastMonth.setMonth(thirdLastMonth.getMonth()-3);const thirdLastMonthFormatted=formatDate(thirdLastMonth);function formatDate(date){const year=date.getFullYear();const month=String(date.getMonth()+1).padStart(2,"0");const day=String(date.getDate()).padStart(2,"0");return `${year}-${month}-${day}`}
const beginDate=lastMonthFormatted;const endDate=todayFormatted;const beginDateSecondLastMonth=secondLastMonthFormatted;const endDateSecondLastMonth=lastMonthFormatted;const beginDateThirdLastMonth=thirdLastMonthFormatted;const endDateThirdLastMonth=secondLastMonthFormatted;console.log("Customer Number:",customerNum);console.log("Card Number:",cardNum);setCustomerNumber(customerNum);setCardNumber(cardNum);setError("");setLoading(!0);const user=currentUser;console.log("user info");console.log(user.uid);console.log(user);const token=user&&(await user.getIdToken());const payloadHeader1={method:"POST",headers:{"Content-Type":"application/json",Authorization:`Bearer ${token}`,},body:JSON.stringify({apiKey:"YOUR_API_KEY",payload:{CustomerNumber:customerNum,CardNumber:cardNum,BeginDate:beginDate,EndDate:endDate,Criteria:1,CardStatus:1,},}),};const res1=await fetch(expayAPIUrl+"/getTransactions",payloadHeader1);const payloadHeader2={method:"POST",headers:{"Content-Type":"application/json",Authorization:`Bearer ${token}`,},body:JSON.stringify({apiKey:"YOUR_API_KEY",payload:{CustomerNumber:customerNum,CardNumber:cardNum,BeginDate:beginDateSecondLastMonth,EndDate:endDateSecondLastMonth,Criteria:1,CardStatus:1,},}),};const res2=await fetch(expayAPIUrl+"/getTransactions",payloadHeader2);const payloadHeader3={method:"POST",headers:{"Content-Type":"application/json",Authorization:`Bearer ${token}`,},body:JSON.stringify({apiKey:"YOUR_API_KEY",payload:{CustomerNumber:customerNum,CardNumber:cardNum,BeginDate:beginDateThirdLastMonth,EndDate:endDateThirdLastMonth,Criteria:1,CardStatus:1,},}),};const res3=await fetch(expayAPIUrl+"/getTransactions",payloadHeader3);const response1=await res1.json();const response2=await res2.json();const response3=await res3.json();const res={response1,response2,response3,};console.log(res);const apiResponse=await res;setApiResponse(apiResponse);setSelectedButton("showData")}}catch(e){console.log(e);setError("Failed to update profile")}
setLoading(!1)};const accountDetails=async(e)=>{e.preventDefault();try{console.log("entered")
const usersRef=ref(database,`${firebaseConfig.dataPath}${currentUser.uid}`);const uid=currentUser.uid;console.log(currentUser)
const snapshot=await get(usersRef);if(snapshot.exists()){const userData=snapshot.val();const customerNum=userData.customerNum;console.log("Customer Number:",customerNum);setCustomerNumber(customerNum);setError("");setLoading(!0);const user=currentUser;console.log("user info");console.log(user.uid);console.log(user);const token=user&&(await user.getIdToken());const payloadHeader={method:"POST",headers:{"Content-Type":"application/json",Authorization:`Bearer ${token}`,},body:JSON.stringify({apiKey:"YOUR_API_KEY",payload:customerNum,}),};const res=await fetch(expayAPIUrl+"/getAccountDetails",payloadHeader);console.log(res);const apiResponse=await res.json();setApiResponse(apiResponse);setSelectedButton("showDataAccount")}}catch(e){console.log(e);setError("Failed to update profile")}
setLoading(!1)};const handleFormLinks=async(e)=>{e.preventDefault();try{setSelectedButton("showDataLinks")}catch(e){console.log(e);setError("Failed to update profile")}
setLoading(!1)};const cardActivation=async(e)=>{e.preventDefault();setShowConfirmationModal(!0)};const handleConfirmation=async()=>{try{const usersRef=ref(database,`${firebaseConfig.dataPath}${currentUser.uid}`);const uid=currentUser.uid;console.log(currentUser)
const snapshot=await get(usersRef);if(snapshot.exists()){const userData=snapshot.val();const customerNum=userData.customerNum;const cardNum=userData.cardNum;const beginDate="2019-01-19";const lastFourDigits=userData.cardNum;console.log("Customer Number:",customerNum);console.log("Card Number:",cardNum);setCustomerNumber(customerNum);setCardNumber(cardNum);setError("");setLoading(!0);const user=currentUser;console.log("user info");console.log(user.uid);console.log(user);const token=user&&(await user.getIdToken());const payloadHeader={method:"POST",headers:{"Content-Type":"application/json",Authorization:`Bearer ${token}`,},body:JSON.stringify({apiKey:"YOUR_API_KEY",payload:{CustomerNumber:customerNum,CardNumber:cardNum,BeginDate:beginDate,LastFourDigits:lastFourDigits,},}),};const res=await fetch(expayAPIUrl+"/CardActivation",payloadHeader);console.log(res);const apiResponse=await res.json();setApiResponse(apiResponse);setSelectedButton("cardActivation");setShowConfirmationModal(!1)}}catch(e){console.log(e);setError("Failed to update profile")}
setLoading(!1)};const handleCancelConfirmation=()=>{setShowConfirmationModal(!1)};useEffect(()=>{document.getElementById("accountDetailsButton").click()},[]);return(<div className="dashboard"><div className="buttons-container"><form onSubmit={accountDetails}><button
id="accountDetailsButton"
className={`profile-button ${
              selectedButton === "showDataAccount" ? "selected" : ""
            }`}
type="submit">Account Details</button></form><form onSubmit={transactionHistory}><button
className={`profile-button ${
              selectedButton === "showData" ? "selected" : ""
            }`}
type="submit">Transaction History</button></form><form onSubmit={cardActivation}><button
className={`profile-button ${
              selectedButton === "cardActivation" ? "selected" : ""
            }`}
type="submit">Card Activation</button></form><form onSubmit={updatePIN}><button
id="accountDetailsButton"
className={`profile-button ${
              selectedButton === "pinchange" ? "selected" : ""
            }`}
type="submit">Update PIN</button></form><form onSubmit={handleFormLinks}><button
id="linkDetailsButton"
className={`profile-button ${
              selectedButton === "showDataLinks" ? "selected" : ""
            }`}
type="submit"
style={{display:"none"}}>Important Info</button></form></div>{}
{apiResponse?(<div className="api-response">{selectedButton==="showData"&&(<div className="table-container"><table><thead className="fixed-header"><tr><th>Date</th><th>Description</th><th>Billing Amount</th><th>Closing Balance</th></tr></thead><tbody>{apiResponse&&apiResponse.response1&&apiResponse.response1.Item!==null&&apiResponse.response1.Item.Pages.map((page,pageIndex)=>page.TransactionList.map((transaction,index)=>(<tr key={index}><td>{new Date(transaction.TransactionDate).toLocaleString("en-US",{timeZone:"America/New_York",})}</td><td>{transaction.Merchant}</td><td>{transaction.TransactionType==="Credit"?(<span className="credit-transaction">-{transaction.BillingAmount}</span>):(<span className="debit-transaction">+{transaction.BillingAmount}</span>)}</td><td>{transaction.WalletBalance}</td></tr>)))}
{apiResponse&&apiResponse.response2&&apiResponse.response2.Item!==null&&apiResponse.response2.Item.Pages.map((page,pageIndex)=>page.TransactionList.map((transaction,index)=>(<tr key={index}><td>{new Date(transaction.TransactionDate).toLocaleString("en-US",{timeZone:"America/New_York",})}</td><td>{transaction.Merchant}</td><td>{transaction.TransactionType==="Credit"?(<span className="credit-transaction">-{transaction.BillingAmount}</span>):(<span className="debit-transaction">+{transaction.BillingAmount}</span>)}</td><td>{transaction.WalletBalance}</td></tr>)))}
{apiResponse&&apiResponse.response3&&apiResponse.response3.Item!==null&&apiResponse.response3.Item.Pages.map((page,pageIndex)=>page.TransactionList.map((transaction,index)=>(<tr key={index}><td>{new Date(transaction.TransactionDate).toLocaleString("en-US",{timeZone:"America/New_York",})}</td><td>{transaction.Merchant}</td><td>{transaction.TransactionType==="Credit"?(<span className="credit-transaction">-{transaction.BillingAmount}</span>):(<span className="debit-transaction">+{transaction.BillingAmount}</span>)}</td><td>{transaction.WalletBalance}</td></tr>)))}</tbody></table></div>)}
{selectedButton==="pinchange"&&(<div>{pinChangeSuccess===!0?(<p className="status-message"><center>Congratulation,your PIN is activated!</center></p>):(<div>{pinChangeSuccess===!1&&(<p className="status-message-error"><center>PIN activation failed.Please try again!</center></p>)}{" "}
{<div>{pinChangeSuccess===""&&(<p className="status-message-error"><center>This link will be valid for 10 minutes{" "}</center>{" "}</p>)}</div>}<form className="account-form" onSubmit={submitPINForm}>{}<div className="form-group"><label htmlFor="yearOfBirth">Year of Birth:</label><input
type="text"
id="yearOfBirth"
className="form-control"
value={yearOfBirth}
onChange={(e)=>setYearOfBirth(e.target.value)}
required/></div>{}<div className="form-group"><label htmlFor="lastFourDigits">Last 4 digits of card:</label><input
type="text"
id="lastFourDigits"
className="form-control"
value={lastFourDigits}
onChange={(e)=>setLastFourDigits(e.target.value)}
required/></div><div className="form-group"><label htmlFor="newpin">New PIN:</label><input
type="text"
id="newPIN"
className="form-control"
value={newPIN}
onChange={(e)=>setnewPIN(e.target.value)}
required
pattern="[0-9]{4}"/><small className="text-muted">Please enter 4 numeric characters.</small></div><button type="submit" className="profile-button">Change your PIN</button></form></div>)}</div>)}
{selectedButton==="cardActivation"&&(<p class="status-message">{apiResponse.ErrorDescription==="Successfull"?"Congratulation, your Card is activated!":"Card activation has failed, Please reach out to admin"}</p>)}
{selectedButton==="showDataAccount"&&apiResponse&&apiResponse.Item&&(<form className="account-form"><div className="form-group"><label htmlFor="customerName">Customer Name:</label><input
type="text"
id="customerName"
className="form-control"
value={apiResponse.Item!=null&&apiResponse.Item.CustomerName!==null&&apiResponse.Item.CustomerName!==undefined?apiResponse.Item.CustomerName:" "}
readOnly/></div><div className="form-group"><label htmlFor="email">Email:</label><input
type="text"
id="email"
className="form-control"
value={apiResponse.Item!=null&&apiResponse.Item.Email!==null&&apiResponse.Item.Email!==undefined?apiResponse.Item.Email:" "}
readOnly/></div><div className="form-group"><label htmlFor="address">Address:</label><input
type="text"
id="address"
className="form-control"
value={(apiResponse.Item!=null&&apiResponse.Item.AddressLine1!==null&&apiResponse.Item.AddressLine1!==undefined?apiResponse.Item.AddressLine1:" ")+" "+(apiResponse.Item!=null&&apiResponse.Item.City!==null&&apiResponse.Item.City!==undefined?apiResponse.Item.City:" ")}
readOnly/></div></form>)}
{selectedButton==="showDataLinks"&&apiResponse&&(<form className="account-form"><div className="button-group"><br></br><br></br><a
href="https://drive.google.com/file/d/1xRfqMZlc5nhFMfwVB2BrG8gKSZlWWvpp/view?usp=share_link"
target="_blank">Resolving the Complaint</a>&nbsp;<a
href="https://drive.google.com/file/d/1tG3TDglshJt0hIyvZgJhH0ukoN5oaiW4/view?usp=sharing"
target="_blank">Prohibited Conduct</a><a
href="https://drive.google.com/file/d/1u2yjVpJGVXHuN65XOFpgvacYx5YLW6yq/view?usp=share_link"
target="_blank">Cardholder Agreement</a></div></form>)}
{}<Modal
isOpen={showConfirmationModal}
onRequestClose={handleCancelConfirmation}
contentLabel="Confirmation Modal"
className="modal"><div className="modal-content"><h2 className="modal-heading"><strong>Confirmation</strong></h2><p className="modal-text">Do you really want to activate the card?This action cannot be
undone.</p><button className="modal-button" onClick={handleConfirmation}>Yes</button><button
className="modal-button"
onClick={handleCancelConfirmation}>No</button></div></Modal>{}<Modal
isOpen={showConfirmationModalForPin}
onRequestClose={handleCancelConfirmationForPin}
contentLabel="Confirmation Modal"
className="modal"><div className="modal-content"><h2 className="modal-heading"><strong>Confirmation</strong></h2><p className="modal-text">Do you want to change you PIN?This Link will be valid for the
next 10 mins</p><button
className="modal-button"
onClick={handleConfirmationForPin}>Yes</button><button
className="modal-button"
onClick={handleCancelConfirmationForPin}>No</button></div></Modal></div>):(<p>Use one of the options to proceed</p>)}</div>)}
