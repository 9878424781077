import React,{useState,useEffect}from "react";import{Link,useNavigate}from "react-router-dom";import{useAuth}from "../../contexts/AuthContext";import{database,firebaseConfig}from "../../config/firebase";import{ref,set}from "firebase/database";export default function Register(){const navigate=useNavigate();const[cardNum,setCardNum]=useState("");const[year,setYear]=useState("");const[password,setPassword]=useState("");const[confirmPassword,setConfirmPassword]=useState("");const[loading,setLoading]=useState(!1);const{currentUser,register,setError}=useAuth();const[showLoader,setShowLoader]=useState(!1);const[cardNumError,setCardNumError]=useState("");const[yearError,setYearError]=useState("");const[passwordError,setPasswordError]=useState("");const[confirmPasswordError,setConfirmPasswordError]=useState("");const[hasRefreshed,setHasRefreshed]=useState(!1);useEffect(()=>{const isRefreshed=localStorage.getItem("refreshed");if(isRefreshed){navigate("/login")}},[currentUser,navigate]);const handleFormSubmit=async(e)=>{e.preventDefault();setCardNumError("");setYearError("");setPasswordError("");setConfirmPasswordError("");if(!/^\d{4}$/.test(cardNum)){setCardNumError("Card number must be exactly 4 digits");return}
const currentYear=new Date().getFullYear();if(!/^\d{4}$/.test(year)||parseInt(year)<1900||parseInt(year)>currentYear){setYearError("Enter a valid year");return}
if(password.length<6){setPasswordError("Password is too weak.");return}
if(password!==confirmPassword){setConfirmPasswordError("Passwords do not match");return}
if(!isStrongPassword(password)){setPasswordError("Password is too weak. Include at least one uppercase letter, one lowercase letter, one number, and one special character.");return}
try{setLoading(!0);const{user}=await register(`${cardNum}${firebaseConfig.emailSuffix}`,password);const userData={email:`${cardNum}${firebaseConfig.emailSuffix}`,cardNum:cardNum,year:year};const usersRef=ref(database,`${firebaseConfig.dataPath}${user.uid}`);await set(usersRef,userData);const isRefreshed=localStorage.getItem("refreshed");if(!isRefreshed){const timeoutId=setTimeout(()=>{window.location.reload();localStorage.setItem("refreshed",!0);setLoading(!1)},3000);setHasRefreshed(!0)}}catch(error){console.error("Error registering:",error);setError("Failed to register")}finally{setLoading(!1)}};const isStrongPassword=(password)=>{const strongPasswordRegex=/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{6,}$/;return strongPasswordRegex.test(password)};return(<div className="flex items-center justify-center py-6 px-4 sm:px-6 lg:px-8"><div className="max-w-md w-full space-y-8"><div><h2 className="mt-4 text-3xl text-center tracking-tight font-light dark:text-white">Register your account</h2></div><form className="mt-8 space-y-1" onSubmit={handleFormSubmit}><div className="rounded-md shadow-sm -space-y-px"><div><input
id="cardNum"
name="cardNum"
type="text"
autoComplete="text"
required
value={cardNum}
onChange={(e)=>setCardNum(e.target.value)}
className="appearance-none rounded-none relative block w-full px-3 py-2 placeholder-gray-500 rounded-t-md bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 focus:z-10 sm:text-sm"
placeholder="Last four digits of Card Number"/>{cardNumError&&(<p className="text-red-500 text-sm">{cardNumError}</p>)}</div><div><input
id="year"
name="year"
type="text"
autoComplete="text"
required
value={year}
onChange={(e)=>setYear(e.target.value)}
className="appearance-none rounded-none relative block w-full px-3 py-2 placeholder-gray-500 rounded-t-md bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 focus:z-10 sm:text-sm"
placeholder="Year of birth"/>{yearError&&(<p className="text-red-500 text-sm">{yearError}</p>)}</div><div><input
id="password"
name="password"
type="password"
autoComplete="current-password"
required
value={password}
onChange={(e)=>setPassword(e.target.value)}
className="appearance-none rounded-none relative block w-full px-3 py-2 placeholder-gray-500 rounded-t-md bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 focus:z-10 sm:text-sm"
placeholder="Password"/>{passwordError&&(<p className="text-red-500 text-sm">{passwordError}</p>)}</div><div><input
id="confirmPassword"
name="confirmPassword"
type="password"
autoComplete="current-password"
required
value={confirmPassword}
onChange={(e)=>setConfirmPassword(e.target.value)}
className="appearance-none rounded-none relative block w-full px-3 py-2 placeholder-gray-500 rounded-t-md bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 focus:z-10 sm:text-sm"
placeholder="Confirm Password"/>{confirmPasswordError&&(<p className="text-red-500 text-sm">{confirmPasswordError}</p>)}</div></div><div><button
type="submit"
disabled={loading}
className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-sky-800 hover:bg-sky-900">{loading?"Registering...":"Register"}</button></div><div className="flex items-center justify-between"><div className="text-sm"><Link
to="/login"
className="text-blue-600 hover:underline dark:text-blue-500">Already have an account?Login</Link></div></div></form>{}
{showLoader&&(<div className="fixed top-0 left-0 right-0 bottom-0 bg-gray-900 bg-opacity-50 flex justify-center items-center z-50"><div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div></div>)}</div></div>)}
